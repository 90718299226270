// EXEMPT
import type { RelatedEntries } from "@/data-models/contentful.mjs";
import { getEntriesByTags } from "@/shared/contentful-fetches.mjs";

export default function fetchData(): Promise<RelatedEntries> {
  return getEntriesByTags(
    ["marvelRivals", "patchNotes"],
    10,
    "id_contains_some",
  );
}
